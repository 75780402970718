/* Global */
@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');
body {
  margin: 0;
  font-family: 'Varela Round', sans-serif !important;
}

a {
  text-decoration: none !important;
}

a > span {
  text-decoration: none !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button > svg {
  font-size: 1rem !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.page-item.active .page-link {
  font-weight: 600;
  color: #fff !important;
  background-color: #fff !important;
  border-color: #b7b6e7 !important;
  border: 1px solid transparent !important;
  min-width: 2.25rem;
}

.form-check-input:checked {
  background-color: #3c39a9 !important;
  border-color: #9fa7d3 !important;
}

/* Page */
.MapCard {
  min-height: 40vh;
  padding: 1rem;
  text-align: center;
  color: #4341a7;
  border: 2px solid #eaf1f9 !important;
  background-color: #f7f8ff !important;
}

.MapCard:hover {
  color: #8886fe;
  box-shadow: 0 3px 3px 2px #8886fe2a;
}

.MapCard > div {
  margin: auto 0rem;
}

.LineChartCard {
  background-color: #fff !important;
  border: #fff !important;
  box-shadow: 0px 0px 5px 6px #afacfc25 !important;
}

.LineChartCard:hover {
  transform: translate(0,-2px) !important;
  transition: .5s !important;
}

.LineChartCard > .TableOverflow > table {
  background-color: #fff !important;
}

.CardList {
  box-shadow: 0px 0px 5px 6px #cecdfc59 !important;
}

.CardList:hover {
  transform: translate(0,-2px) !important;
  transition: .5s !important;
}

.CardListPurple {
  background-color:#8886fe2a !important;
  box-shadow: 0px 0px 5px 6px #cecdfc59 !important;
}

.CardListPurple:hover {
  transform: translate(0,-2px) !important;
  transition: .5s !important;
}

/* Forms & Tables */
table {
  width: 100%;
  table-layout: fixed;
  border-radius: .25rem;
  border-width: 0px;
  border-top-width: 0px !important;
  border-bottom-width: 0px !important;
  border-left-width: 0px !important;
  border-right-width: 0px !important;
  border-color: rgb(227, 228, 236) !important;
  --bs-table-hover-bg: rgb(244 246 255) !important;
}

.table>:not(caption)>*>* {
  border-radius: .25rem !important;
  padding: .23rem .5rem !important;
}

tr:first-child {
  border-top-width: 0px !important;
  /* border-bottom-width: 0px !important; */
  border-left-width: 0px !important;
  border-right-width: 0px !important;
}

tr:last-child, th:first-child, th:last-child {
  border-top-width: 0px !important;
  border-bottom-width: 0px !important;
  border-left-width: 0px !important;
  border-right-width: 0px !important;
}

td:first-child, td:last-child {
  border-top-width: 0px !important;
  border-bottom-width: 0px !important;
  border-left-width: 0px !important;
  border-right-width: 0px !important;
}

th {
  text-align: center !important;
}


/* Special td padding class for Tables Row that without any icon (to fix heighting probs) */
.Table-DefaultHeight {
  display: flex !important;
  flex-direction: column !important;
  height: 100% !important;
}

.Table-DefaultHeight > .TableOverflow > table > tbody > tr > td {
  padding: 0.605rem 1.25rem !important;
}

/* for Adjust the table width */
.Table-AutoWidth > .TableOverflow > table {
  table-layout: auto !important;
}

#Table-Resize > .TableOverflow > table > thead > tr > th:nth-child(2) {
  width: 7%;
}

#Table-Resize > .TableOverflow > table > thead > tr > th:nth-child(3){
  width: 7%;
}

#Table-Resize > .TableOverflow > table > thead > tr > th:nth-child(4){
  width: 7%;
}

#Table-Resize > .TableOverflow > table > thead > tr > th:nth-last-child(1){
  width: fit-content !important;
}

#Table-td-Resize {
  height: 45px !important;
}

.importCheckTable > thead {
  border-bottom: 1.5px dashed #afafaf;
}

.importCheckTable > thead > tr {
  height: 2.5rem;
}

.importCheckTable > thead > tr > td {
  height: 2rem;
}

.importCheckTable > tbody > tr > td:nth-child(1) {
  text-indent: 45%;
}

.importCheckTable > tbody > tr > td:nth-child(2) {
  text-indent: 5%;
  text-overflow: ellipsis;
  overflow-x: hidden;
}

.importCheckTable > tbody > tr > td:nth-child(3) {
  text-indent: 25%;
}



/* Class for ORCA */
.bgImg1 {
  background-image: url("../../assets/images/orca-bg3.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.bgImg_gif {
  background-image: url("../../assets/images/orca-bg-gif.gif");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  -webkit-filter: brightness(.9);
  z-index: 1 !important;

}

.text-orca {
  color: #2a2972 !important;
}

.text-orcaMid {
  color: rgb(67, 65, 167) !important;
}

.text-orcaLight {
  color: #7170c2 !important;
}

.text-orcaLighter {
  color: #9fa7d3 !important;
}

.text-orcaIcon {
  color: #2a2972 !important;
}

.text-orcaIcon:hover {
  color: #9fa7d3 !important;
}

.text-orcaLink {
  color: #7170c2 !important;
}

.text-orcaLink:hover {
  color: #9fa7d3 !important;
}

.text-orcaIcon2 {
  color: #9fa7d3 !important;
}

.text-orcaIcon2:hover {
  color: #2a2972 !important;
}

.text-point {
  color: #ec4d53 !important;
}

.bg-orca {
  background-color: #05042e !important;
}

.bg-orca2 {
  background-color: #070509 !important;
}


.bg-orcaMid {
  background-color: #3c39a9 !important;
}

.bg-orcaLight {
  background-color: #b7bddf !important;
}

.bg-orcaLighter {
  background-color: #ebedf9 !important;
}

.bg-orcaWhite {
  background-color: #ebedf963 !important;
}

.bg-transpWhite {
  background: #ffffffea !important;
}


.btn-orca {
  color: #fff !important;
  background-color: #4341a7 !important;
}

.btn-orca:hover {
  color: #05042e !important;
  background-color: #b7b6e7 !important;
}


.btn-orcaMid {
  color: #fff !important;
  background-color: #a3a9ca !important;
}

.btn-orcaMid:hover {
  color: #05042e !important;
  background-color: #b7b6e7 !important;
}


.btn-orca-edit {
  color: #3c39a9 !important;
  background-color: transparent !important;
}

.btn-orca-edit:hover {
  color: #0cad78 !important;
}

.btn-orca-search {
  color: #3c39a9 !important;
  background-color: transparent !important;
}

.btn-orca-search:hover {
  color: #2ecefa !important;
}

.btn-orca-unschedule {
  color: #3c39a9 !important;
  background-color: transparent !important; 
}

.btn-orca-unschedule:hover {
  color: #ce4646 !important;
  background-color: transparent !important; 
}

.btn-orca-delete {
  color: #3c39a9 !important;
  background-color: transparent !important;
}

.btn-orca-delete:hover {
  color: #ce4646 !important;
}

.btn-orca-ban {
  color: #ccc !important;
  background-color: transparent !important;
}

.btn-orca-ban:hover{
  color: rgb(243, 165, 165) !important;
  font-weight: bolder;
}

.btn-orca-ban:active, .btn-orca-ban:focus {
  color: #ce4646 !important;
  font-weight: bolder;
}

.bth-orca-download {
  color: #3c39a9 !important;
  background-color: transparent !important;
}

.bth-orca-download:hover {
  color: #0008fc !important;
}

.btn-orca-facebook {
  color: #3c39a9 !important;
  background-color: transparent !important;
}

.btn-orca-facebook:hover {
  color: #3b62b6 !important;
}

.btn-orca-facebook:active, .btn-orca-facebook:focus {
  color: #1e00ff !important;
}
.btn-orca-unban {
  color: #ce4646 !important;
  font-weight: bolder;
}

.btn-orca-unban:hover{
  color: rgb(243, 165, 165) !important;
  font-weight: bolder;
}

.btn-orca-unban:active, .btn-orca-ban:focus {
  color: #ccc !important;
  background-color: transparent !important;
}


.btn-orcaLight {
  color: #4341a7 !important;
  background-color: #e7ebff !important;
  border: none !important;
}

.btn-orcaLight:hover {
  color: #fff !important;
  background-color: #bec3e3 !important;
}


.btn-orcaDark {
  color: #8886fd !important;
  background-color: #05042e !important;
}

.btn-orcaDark:hover {
  color: #fff !important;
  background-color: #b7bddf !important;
}


.btn-IconLight {
  color: #fff !important;
  background-color: transparent !important;
  border: none !important;
}

.btn-IconLight:hover {
  color: rgba(255, 255, 255, 0.822) !important;
  background-color: transparent !important;
  border: none !important;
}

.btn-Apply > button {
  width: 100%;
  margin-left: auto;
}

.btn-noFocus:hover, .btn-noFocus:focus, .btn-noFocus:active {
  box-shadow: none !important;
}

.btn-test {
  color: rgb(114, 51, 35) !important;
  background-color: rgb(240, 221, 216) !important;
  border: none !important;
}

.btn-test:hover {
  color: #fff !important;
  background-color: rgb(223, 189, 180) !important;
  border: none !important;
}


.btn-send {
  color: rgb(15, 73, 30) !important;
  background-color: rgb(210, 240, 217) !important;
  border: none !important;
}

.btn-send:hover {
  color: #fff !important;
  background-color: rgb(151, 207, 165) !important;
  border: none !important;
}

.btn-schedule {
  color: #4341a7 !important;
  background-color: #e7ebff !important;
  border: none !important;
}

.btn-schedule:hover {
  color: #fff !important;
  background-color: #b7bddf !important;
  border: none !important;
}

.btn-dropdownTitle {
  color: #4341a7 !important;
  background-color: #fff !important;
  border: none !important;
  font-size: 1.35rem !important;
  font-weight: 600 !important;
  box-shadow : none !important;
}

.btn-dropdownTitle:hover {
  color: #7170c2 !important;
}

.btn-dropdownTitle:active, .btn-dropdownTitle:focus {
  border: none !important;
}

.dropdown-toggle::after {
  color: #b7b6e7 !important;
}

.btn-default {
  text-align: start !important;
  background-color: white;
  border-color: hsl(0, 0%, 80%) !important;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  color: hsl(0, 0%, 50%) !important;
  grid-area: 1/1/2/3 !important;
  margin-left: 2px;
  margin-right: 2px;
}

.btn-outline-orca {
  color: #4341a7 !important;
  border-color: #4341a7 !important;
  -webkit-hover-color: #fff;
  -webkit-hover-bg: #4341a7;
  -webkit-hover-border-color: #4341a7;
  -webkit-focus-shadow-rgb: 220,53,69;
  -webkit-active-color: #fff;
  -webkit-active-bg: #4341a7;
  -webkit-active-border-color: #4341a7;
  -webkit-active-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
  -webkit-disabled-color: #4341a7;
  -webkit-disabled-bg: transparent;
  -webkit-gradient: none;
}

.btn-outline-orca:focus, .btn-outline-orca:hover, .btn-outline-orca:active {
  color: #fff !important;
  background-color: #4341a7 !important;
  border-color: #4341a7 !important;
}



/* Navbar */
.LoginPage > nav {
  background-color: #070509 !important;
}

.dropdown-toggle::after {
  color: #fff;
}

.dropdown-item:focus, .dropdown-item:hover {
  color: #4341a7 !important;
  background-color: rgb(244 246 255) !important;
}

.noDropdownIcon .dropdown-toggle::after {
  content:'' !important;
  border: 0 !important;
}

.bellBadge {
  height: fit-content;
  align-self: flex-start;
  padding: 0.12rem 0.35rem !important;
  position: absolute;
  margin-left: 25px;
}

.NotiHover > .row:hover {
  background-color: #ebedf9 !important;
}

.uploading-icon {
  height: 1.5rem !important;
  width: 1.5rem !important;
  animation: 1.5s linear infinite spinner-border !important;
}

.passwordChangingSpinner {
  margin-top: .75rem;
}

/* Sidebar */
.sidebar {
  padding: 1rem !important;
}

.sidebar > .row > a {
  line-height: 3;
  font-size: 1.25vw;
  font-weight: 300;
  background: transparent;
  text-decoration: none;
  color: #4341a7 !important;
}

.sidebar > .row > a:hover {
  border-color: #8886fe !important;
  box-shadow: 0 0 0 0rem !important;
  color: #8886fe !important;
  background-color: #e9ebf8 !important;
}

.sidebarHover-on {
  border-color: #8886fe !important;
  box-shadow: 0 0 0 0rem !important;
  color: #4341a7 !important;
  background-color: #e9ebf8 !important;
}

.sidebarHover-off {
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: 0 0 0 0rem !important;
  color: #4341a7 !important;
}

/* For hide hover effect of Audience */
#sidebarHover-disabled:hover {
  color: #4341a7 !important;
  background-color: transparent !important;
  cursor: default !important;
}

.sidebar::-webkit-scrollbar {
  width: .5em;
}

.sidebar::-webkit-scrollbar-button {
  background: transparent;
}

.sidebar::-webkit-scrollbar-track-piece {
  background: transparent;
}

.sidebar::-webkit-scrollbar-track {
  box-shadow: inset 0px transparent;
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: #4341a7;
  border-radius: 50rem;
}

.scroller::-webkit-scrollbar {
  width: .4em;
}

.scroller::-webkit-scrollbar-button {
  background: transparent;
}

.scroller::-webkit-scrollbar-track-piece {
  background: transparent;
}

.scroller::-webkit-scrollbar-track {
  box-shadow: inset 0px transparent;
}

.scroller::-webkit-scrollbar-thumb {
  background-color: #ced4da;
  border-radius: 50rem;
}

/* Hidden scroller */
.ScrollbarHide::-webkit-scrollbar {
  width: 0em;
}

.ScrollbarHide::-webkit-scrollbar-button {
  background: transparent;
}

.ScrollbarHide::-webkit-scrollbar-track-piece {
  background: transparent;
}

.ScrollbarHide::-webkit-scrollbar-track {
  box-shadow: inset 0px transparent;
}

.ScrollbarHide::-webkit-scrollbar-thumb {
  background-color: #4341a7;
  border-radius: 50rem;
}

/* Homepage */
.HomepageCard::-webkit-scrollbar {
  width: .5em;
}

.HomepageCard::-webkit-scrollbar-button {
  background: transparent;
}

.HomepageCard::-webkit-scrollbar-track-piece {
  background: transparent;
}

.HomepageCard::-webkit-scrollbar-track {
  box-shadow: inset 0px transparent;
}

.HomepageCard::-webkit-scrollbar-thumb {
  background-color: #4341a7;
  border-radius: 50rem;
}


/* Chart */
#chartArea {
  margin: auto;
  text-align: center !important;
}


/* Table or Forms */
.TableOverflow {
  overflow-y: auto !important;
}

.TableOverflow::-webkit-scrollbar {
  width: .5em;
}

.TableOverflow::-webkit-scrollbar-button {
  background: transparent;
}

.TableOverflow::-webkit-scrollbar-track-piece {
  background: transparent;
}

.TableOverflow::-webkit-scrollbar-track {
  box-shadow: inset 0px transparent;
}

.TableOverflow::-webkit-scrollbar-thumb {
  background-color: #4341a7;
  border-radius: 50rem;
}

.TableForms {
  margin-bottom: 0 !important;
  overflow: hidden;
}

.TableForms > tbody > tr > td {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: middle;
}

.TableForms > thead > tr > th {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: middle; 
}


/* Pagination */
#TablePagination > .row{
  flex-direction: column !important;
}

#TablePagination > .row > .pagination {
  border: 1px solid #ced4da;
  border-radius: 5px;
}

#TablePagination > .row > .pagination > li {
  flex-grow: 1;
}

#TablePagination > .row > .pagination > li > a {
  display: flex;
}

#TablePagination > .row > .pagination > li > a > span {
  margin-left: auto !important;
  margin-right: auto;
  color: transparent;
}

#TablePagination > .row > .pagination > .active {
  background-color:#fff;
  align-self: center;
}

#TablePagination > .row > .pagination > .active > span {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  border-radius: .5rem !important;
  box-sizing: border-box;
  border: 5px solid;
}

#TablePagination > .row > .pagination > .prev > a {
  background-image: url('../images/arrow-left.svg');
  background-repeat: no-repeat;
  background-size: 3%;
  background-position-y: center;
  background-position-x: 3%;
  color: #2a2972;
  border: 1px solid #fff;
}

#TablePagination > .row > .pagination > .next > a {
  background-image: url('../images/arrow-right.svg');
  background-repeat: no-repeat;
  background-size: 3%;
  background-position-y: center;
  background-position-x: 97%;
  color: #2a2972;
  border: 1px solid #fff;
}

/* Dialog */
.ContactUs {
  background-color: #e7ebff;
  width: 100%;
}

.ContactUs > a {
  text-decoration: none;
  color: #b7b6e7;
  font-weight: 500;
  font-size: .9rem;
  margin: 0 auto;
}

.ContactUs > a:hover {
  color: #4341a7;
}

.listSelector {
  border: 1px solid #ced4da;
  border-radius: .25rem;
  display: block !important;
}

.listSelector > .list-group {
  padding: 0rem;
  height: 41px;
}

.listSelector > .list-group:nth-child(1) > div {
  border-top: none;
}

.listSelector > .list-group > div {
  border-bottom: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
  height: 100%;
  display: flex !important;
}

.listSelector > .list-group > div:hover {
  background-color: rgb(244 246 255);
}

.listSelector::-webkit-scrollbar {
  width: .4em;
}

.listSelector::-webkit-scrollbar-button {
  background: transparent;
}

.listSelector::-webkit-scrollbar-track-piece {
  background: transparent;
}

.listSelector::-webkit-scrollbar-track {
  box-shadow: inset 0px transparent;
}

.listSelector::-webkit-scrollbar-thumb {
  background-color: #bec3e3;
  border-radius: 50rem;
}

/* Analytics */
.AnaOverflow::-webkit-scrollbar {
  width: .5em;
}

.AnaOverflow::-webkit-scrollbar-button {
  background: transparent;
}

.AnaOverflow::-webkit-scrollbar-track-piece {
  background: transparent;
}

.AnaOverflow::-webkit-scrollbar-track {
  box-shadow: inset 0px transparent;
}

.AnaOverflow::-webkit-scrollbar-thumb {
  background-color: #4341a7;
  border-radius: 50rem;
}

/* React Select */
[id*="-option-"] {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: middle;
}

#transp_select,
#transp_select > span,
#transp_select > div,
#transp_select > div > div > span {
  background-color: transparent !important;
  border: 0px solid transparent !important;
  user-select: none !important;
}

#transp_select > div > div > div {
  background-color: transparent !important;
  border: 0px solid transparent !important;
  padding: 0 !important;
  color: #2a2972 !important;
}

#transp_select > div > div > div > svg {
  display: none !important;
}

.selectNoPadding > div > div > div > div:has(svg) {
  padding: 1px;
}

.selectNoPadding > div > div:has(input) {
  padding: 0px 2px;
}

.selectNoPadding > div > div > div:has(div) {
  padding: 2px 1px !important;
}

/* .selectNoPadding > div > div > div:has(div) > div {
  text-overflow: unset;
} */

/* Sms Templates */
.speech-bubble-default {
	position: relative;
	background: #ebedf9;
	border-radius: .5em;
  min-height: 2.5rem; 
  height: auto;
}

.speech-bubble-default:after {
	content: '';
	position: absolute;
	left: 0;
	top: 70%;
	width: 0;
	height: 0;
	border: 0.719em solid transparent;
	border-right-color: #ebedf9;
	border-left: 0;
	border-top: 0;
	margin-top: -0.359em;
	margin-left: -0.719em;
}

.speech-bubble-mid {
	position: relative;
	background: #ebedf9;
	border-radius: .5em;
  min-height: 2.5rem; 
  height: auto;
}

.speech-bubble-mid:after {
	content: '';
	position: absolute;
	left: 0;
	top: 85%;
	width: 0;
	height: 0;
	border: 0.719em solid transparent;
	border-right-color: #ebedf9;
	border-left: 0;
	border-top: 0;
	margin-top: -0.359em;
	margin-left: -0.719em;
}

.speech-bubble-bottom {
	position: relative;
	background: #ebedf9;
	border-radius: .5em;
  min-height: 2.5rem; 
  height: auto;
}

.speech-bubble-bottom:after {
	content: '';
	position: absolute;
	left: 0;
	top: 95%;
	width: 0;
	height: 0;
	border: 0.719em solid transparent;
	border-right-color: #ebedf9;
	border-left: 0;
	border-top: 0;
	margin-top: -0.359em;
	margin-left: -0.719em;
}

.ruleInfo:hover {
  color: #2a2972 !important;
}

.btn-transp {
  background-color: transparent !important;
  border: 1px solid transparent !important;
}

.btn-transp::after {
  background-color: transparent !important;
}

/* Email Template & Sending */
#mailPreview > html {
  height: 100% !important;
}

#mailFooterPreview {
  flex-wrap: nowrap !important;
  width: fit-content;
}

#mailFooterPreview > span {
  pointer-events: none;
  user-select: none;
}

#mailFooterPreview > input {
  background-color: transparent;
  color: #2a2972;
  font-size: .875rem;
  text-align: center;
  padding: 0;
  border: 0;
  pointer-events: none;
  display: inline-block;
  width: fit-content;
}

.MailCampaignPreview .form-label {
  margin-bottom: 3px;
  color: #2a2972;
}

.MailCampaignPreview html{
  overflow-x: visible !important;
}

/* Tooltip */
.Tips > .tooltip-inner {
  max-width: 290px;
  background-color: rgba(0, 0, 0, 0.815);
  box-shadow: 0px 0px 2px 0px #64646485;
  border-color: transparent;
  text-align: left;
}

.Tips-rule > .tooltip-inner {
  min-width: 13rem !important;
  background-color: rgba(0, 0, 0, 0.827);
  box-shadow: 0px 0px 2px 0px #64646485;
  border-color: transparent;
}

.Tips-rule > .tooltip-inner > .row {
  display: flex !important;
}

.Tips-mailCampaign > .tooltip-inner {
  max-width: 500px !important;
  background-color: rgba(0, 0, 0, 0.827);
  box-shadow: 0px 0px 2px 0px #64646485;
  border-color: transparent;
  height: fit-content;
  flex-wrap: nowrap !important;
}

.Tips-mailCampaign > .tooltip-inner > .row {
  display: flex !important;
}

/* Date Time Picker */
.picky-date-time {
  box-shadow: none !important;
  padding-top: 10px !important;
  padding-bottom: 5px !important;
}

.picky-date-time-clock__inline-div--middle > svg {
  width: 1.5rem !important;
  height: 1.5rem !important;
  margin-left: .5rem;
}

.picky-date-time-clock__inline-div--middle > svg:hover {
  cursor: pointer !important;
}

.picky-date-time-calendar__button {
  display: flex !important;
  color: #3c39a9 !important;
  background-color: #d0d4ed !important;
  border: 1px solid #d0d4ed !important;
  border-radius: .25rem !important;
  font-size: .95rem !important;
  padding: .5rem !important;
  margin-top: 0 !important;
}

.picky-date-time-calendar__button:hover {
  background: #b7b6e7 !important;
  border: 1px solid #b7b6e7 !important;
}

.picky-date-time-calendar__button > span {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.picky-date-time-calendar__button > svg {
  color: #3c39a9 !important;
  margin-left: .3rem;
  width: 1.2rem !important;
  height: 1.2rem !important;
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.picky-date-time-calendar__table-cel.active:not(.today),
.picky-date-time-calendar__table-cel.picky-date-time-calendar__date-item:not(.disabled):hover {
  background: #b7b6e7 !important;
  border: 1px solid #b7b6e7 !important;
}

.picky-date-time-clock__clock-hand--second {
  background: #7170c2 !important;
}

.picky-date-time-calendar__title:hover {
  color: #7170c2 !important;
}

.picky-date-time-clock__circle {
  margin: 18px auto 42px !important;
}

.picky-date-time-clock__inputer {
  border-radius: .25rem !important;
  padding: .45rem !important;
}

.picky-date-time-calendar__body-container {
  height: 255px !important;
}

.picky-date-time__calendar {
  width: auto !important;
}

.picky-date-time__close {
  display: none !important;
}

/* Date Time React-Calender */
.react-calendar {
  border: none !important;
  line-height: 1.75rem !important;
  font-family: 'Varela Round', sans-serif !important;
}

.react-calendar__tile:enabled:hover {
  background-color: transparent !important;
  color: #4341a7 !important;
}

.react-calendar__tile--active,
.react-calendar__tile:enabled:focus,
.react-calendar__tile--hasActive {
  background: #3d39a9 !important;
  color: #fff !important;
  border-radius: .25rem;
}

.react-calendar__tile--now {
  background: #e7ebff !important;
  color: #4341a7 !important;
  border-radius: .25rem;
}

.react-calendar__month-view__days__day--weekend:enabled:hover {
  color: #e83f3f !important;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 1.22rem 0.5rem !important;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e7ebff !important;
  border-radius: .25rem;
}

/* Tools class */

.text-nowarp {
  flex-wrap: nowrap !important;
  overflow-wrap: nowrap !important;
}

.noLinkDeco {
  text-decoration: none !important;
}

.oneLineLimit {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: middle;
}

.twoLineLimit {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.dropdown-item {
  display: block !important;
}

.modal-90w {
  width: 90vw !important;
  max-width: 90vw !important;
}

.modal-100w {
  width: 100vw !important;
  max-width: 100vw !important;
  height: 100vh !important;
  max-height: 100vh !important;
}

.h7 {
  font-size: .9rem;
  font-weight: 500;
}

.vh-7 {
  height: 7vh;
}

.vh-8 {
  height: 8vh;
}

.vh-80 {
  height: 80vh;
}

.vh-85 {
  height: 85vh;
}

.vh-92 {
  height: 92vh;
}

.vw-25 {
  width: 25vw !important;
}

.vw-28 {
  width: 28vw !important;
}

.w-80 {
  width: 80% !important;
}

.w-85 {
  width: 80% !important;
}

.w-49 {
  width: 49% !important;
}

.w-20 {
  width: 20% !important;
}

.w-15 {
  width: 15% !important;
}

.w-17 {
  width: 17% !important;
}

.w-1 {
  width: 1% !important;
}

.h-5 {
  height: 5% !important;
}

.h-6 {
  height: 6% !important;
}

.h-20 {
  height: 20% !important;
}

.h-30 {
  height: 30% !important;
}

.h-33 {
  height: 33.333% !important;
}

.h-35 {
  height: 35% !important;
}

.h-40 {
  height: 40% !important;
}

.h-55 {
  height: 55% !important;
}

.h-60 {
  height: 60% !important;
}

.h-65 {
  height: 65% !important;
}

.h-70 {
  height: 70% !important;
}

.h-75 {
  height: 75% !important;
}

.h-80 {
  height: 80% !important;
}

.h-85 {
  height: 85% !important;
}

.h-90 {
  height: 90% !important;
}

.h-92 {
  height: 92% !important;
}

.h-95 {
  height: 95% !important;
}

.h-97 {
  height: 97% !important;
}

.py-8 {
  padding-top: 8.6rem;
  padding-bottom: 8.6rem;
}

.py-9 {
  padding-top: 9rem;
  padding-bottom: 9rem;
}

.py-10 {
  padding-top: 10rem;
  padding-bottom: 10rem;
}

.whiteForce {
  filter: brightness(100);
}

.opac8 {
  opacity: .8 !important;
}

.bg-transp {
  background-color: transparent !important;
}

.fs-7 {
  font-size: 0.93rem !important;
}

.fs-8 {
  font-size: 0.8rem !important;
}

.child-w-100 > button {
  width: 100%;
}

.text-green{
  color: rgb(73, 170, 105) !important;
}

.text-grey, .text-grey:hover {
  color: rgb(116, 116, 116) !important;
}

.text-greier, .text-greier:hover {
  color: rgb(170, 170, 170) !important;
}

.text-blue, .text-blue:hover {
  color: #125cca !important;
}

.text-red, .text-red:hover {
  color: #be1b1b !important;
}

/* TabsCard */
#TabColumn {
  display: flex;
  flex-direction: column;
  height: 100%; 
}

.TabsCard {
  overflow-y: hidden;
}

.TabsCard > .nav {
  margin-left: auto;
  margin-top: auto;
  border-bottom: none !important;
  overflow-y: hidden;
}

.TabsCard > .tab-content {
  height: 94%;
  z-index: 5;
  background-color: #f8f9fa;
  border-bottom-left-radius: .25rem;
  border-bottom-right-radius: .25rem;
  border-top-right-radius: .25rem;
}

.TabsCard > .tab-content > .tab-pane {
  height: 100% !important;
}

.TabsCard > button {
  margin-left: .75rem;
  margin-bottom: .5rem;
  height: -webkit-fill-available;
}

.TabsCard > ul > li > button {
  color: #7170c2;
  background-color: #d5d9ed !important;
  border: 1px solid transparent !important;
  min-width: 12vw;
  height: 100%;
  z-index: -2;
}

.TabsCard > ul  > li > button:hover {
  color: #2a2972;
  background-color: #bec3e3;
  border-bottom: 1px solid #fff;
}

.TabsCard > ul > li > .active {
  color: #8886fe !important;
  background-color: #f8f9fa !important;
  font-weight: 500;
}

.TabsCard > .tab-content > .tab-pane {
  border-top-left-radius: 0px !important;
}

/* LucaDatePicker */
.reportDatePicker > .react-datepicker-wrapper > .react-datepicker__input-container {
  height: 100%;
}

.reportDatePicker > .react-datepicker-wrapper > .react-datepicker__input-container > input {
  border: 1px solid #ced4da;
  border-radius: .25rem;
  height: 100%;
}

/* report directoy detail */
.reportDirectoryDetail > .accordion-item {
  border: 0 !important;
  border-radius: 0 !important;
}
.reportDirectoryDetail > .accordion-item > .accordion-header > button {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
}

/* for Demo */
#demoCsvImporter {
  border-radius: .5rem;
  border: 1px solid #4341a7;
  padding: 2rem;
  height: 100%;
  background-color: #e9ecef;
  text-align: center;
  align-content: center;
  margin-bottom: 0 !important;
  display: flex;
}

#demoCsvImporter:hover {
  background-color: #f8f9fa;
  cursor: pointer;
}


/* Rotate animation */
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (prefers-reduced-motion: no-preference) {
  .load-icon {
    animation: load-icon-spin infinite 15s linear;
  }
}

@keyframes load-icon-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (prefers-reduced-motion: no-preference) {
  .load-icon {
    animation: load-icon-spin infinite 2s linear;
  }
}

